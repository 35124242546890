import { Abide } from 'foundation-sites/js/foundation.abide';
import { Orbit } from 'foundation-sites/js/foundation.orbit';

Abide.defaults.validateOnBlur = true;
Abide.defaults.patterns['name'] = /^[a-zA-Z]+([ .\-][a-zA-Z]+)*$/;
Abide.defaults.patterns['address'] = /^(?:(\d+\s(?:\d+(?:st|nd|rd|th)?\s)?(?:[A-Za-z0-9\-\.]+(?:\s[A-Za-z0-9\-\.]+)*)(?:\s(?:N|S|E|W|North|South|East|West))?)|(?:PO Box|P\.O\. Box|P\.O\.B|POB|RR)\s?\d+(?:\s?Box\s?\d+)?|(?:PSC\s\d+,?\sBox\s\d+|(?:APO|FPO|DPO)\s(?:AA|AE|AP)\s\d{5})|(?:[A-Za-z0-9\-\.]+(?:\s[A-Za-z0-9\-\.]+)+))$/i;
Abide.defaults.patterns['city'] = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*\s*$/;
Abide.defaults.patterns['phone-number'] = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
Abide.defaults.patterns['slug'] = /^[A-Za-z][A-Za-z0-9\-]+$/;
Abide.defaults.patterns['currency_usd'] = /^\$?[-+]?\d*(?:[\.\,]\d+)?$/;
Abide.defaults.patterns['percentage'] = /^[-+]?\d*(?:[\.\,]\d+)?%?$/;

/*
 * Custom validator to verify the maximum length of an input field, inclusive.
 * Usage:
 * <input type="text" data-validator="max-length" data-max-length="50" />
 */
Abide.defaults.validators['max-length'] =
function($el,required,parent) {
  var maxLength = $el.attr('data-max-length');
  return (parseInt($el.val().length) <= parseInt(maxLength));
};

/*
 * Custom validator to verify the minimum length of an input field, inclusive. Optionally can include attribute
 * 'data-min-length-allow-empty' to allow empty values for a field
 * Usage:
 * <input type="password" data-validator="min-length" data-min-length="6" data-min-length-allow-empty="true" />
 */
Abide.defaults.validators['min-length'] =
function($el,required,parent) {
  var minLength = parseInt($el.attr('data-min-length'));
  var valLength = $el.val().length;

  return $el.attr('data-min-length-allow-empty') == undefined ? valLength >= minLength : (valLength == 0 || valLength >= minLength)
};

/*
 * Custom validator to verify an input is within a numerical range of values, inclusive. Optionally can include attribute
 * 'data-between-allow-empty' to allow empty values for a field
 * Usage:
 * <input type="text" data-validator="between" data-min-value="0" data-max-value="10" />
 */
Abide.defaults.validators['between'] =
function($el,required,parent) {
  var minValue = parseFloat($el.attr('data-min-value'));
  var maxValue = parseFloat($el.attr('data-max-value'));
  var value = parseFloat($el.val());

  return $el.attr('data-between-allow-empty') == undefined ? (minValue <= value &&  value <= maxValue) : ($el.val().length == 0 || (minValue <= value &&  value <= maxValue))
};

/*
 * Custom validator to verify an zip code exists in the fastweb database.
 * Usage:
 * <input type="text" data-validator="zip_code" />
 */
Abide.defaults.validators['zip-code'] =
function($el,required,parent) {
  var zipCode = $el.val();
  if (zipCode.length != 5) {
    return false;
  }

  var data = {user: {zip_code: zipCode}};
  var form = $el.closest('form');

  $.ajax({
    url: document.location.origin + '/validate/zip_code',
    data: data,
    dataType: 'text',
  }).done(function(data, textStatus, jqXHR) {
    form.foundation('removeErrorClasses', $el);
    $el.trigger('valid.zf.abide', [$el]);
    return true;
  }).fail( function(jqXHR, textStatus, errorThrown) {
    form.foundation('addErrorClasses', $el);
    $el.trigger('invalid.zf.abide', [$el]);
    return false;
  });

  if($el.attr('data-invalid') == undefined) {
    return true;
  } else {
    return false;
  }
}

/*
 * Validator to verify email domain.
 * Usage:
 * <input type="text" data-validator="email-domain" />
 */
Abide.defaults.validators['email-domain'] =
function($el,required,parent) {
  var email = $el.val();
  var source = $el.attr('data-source');
  var data = {email: email};
  if (source) data['source'] = source;
  var form = $el.closest('form');
  var emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
  if(!emailPattern.test(email)) {
    form.foundation('addErrorClasses', $el);
    $el.trigger('invalid.zf.abide', [$el]);
    return false;
  }

  $.ajax({
    url: document.location.origin + '/validate/validate_email_domain',
    data: data,
    dataType: 'text',
  }).done(function(data, textStatus, jqXHR) {
    form.foundation('removeErrorClasses', $el);
    $el.trigger('valid.zf.abide', [$el]);
    return true;
  }).fail( function(jqXHR, textStatus, errorThrown) {
    form.foundation('addErrorClasses', $el);
    $el.trigger('invalid.zf.abide', [$el]);
    return false;
  });

  if($el.attr('data-invalid') == undefined) {
    return true;
  } else {
    return false;
  }
}

/*
 * Custom validator to verify a user has selected at least one value from an autocomplete field.
 * Assumes that the value selected by the user has been injected into the page html nested within
 * a 'ul' element that has the class specified by the data-autocomplete-populater-class attribute.
 * Usage:
 * <input type="text" data-validator="autocomplete-populated" data-autocomplete-populated-class="selected_list" />
 */
Abide.defaults.validators['autocomplete-populated'] =
function($el, required, parent) {
  var hiddenClass = $el.attr('data-autocomplete-populated-class');
  if ($('.' + hiddenClass).find('li:visible').find("input[type=hidden]").length >= 1) {
    return true;
  } else {
    return false;
  }
}

/*
 * Custom validator to verify that a minimum number of checkboxes in a group of checkboxes have been selected.
 * Assumes that the checkboxes are nested within an element that has the attribute data-behavior="checkbox-group"
 * and attribute data-min-required defined. Each checkbox within the group should then specifiy the
 * data-validator='checkbox-group' attribute
 * Usage:
 * <fieldset data-behavior="checkbox-group" data-min-required="1">
 *   <input type="checkbox" value="1" data-validator="checkbox-group"> 1
 *   <input type="checkbox" value="2" data-validator="checkbox-group"> 2
 * </fieldset>
 */
Abide.defaults.validators['checkbox-group'] =
function($el, required, parent) {
  var groupElement = $($el).closest('[data-behavior=checkbox-group]');
  var minRequired = parseInt($(groupElement).attr('data-min-required'));

  if($(groupElement).find('input[type=checkbox]:checked').length >= minRequired) {
    $(groupElement).find('input[type=checkbox]').each(function(index, input) {
      $(groupElement).closest('form').foundation('removeErrorClasses', $(input));
      $(input).trigger('invalid.zf.abide', [input]);
    });
    return true;
  } else {
    return false;
  }
}

/*
 * Custom validator to ensure that impression pixels follow certains rules. The src property of the pixel tag must use
 * the https protocol and the impression pixel cannot container smart/curly quotes.
 * Usage:
 * <textarea data-validator="impression-pixel" />
 */
Abide.defaults.validators['impression-pixel'] =
function($el, required, parent) {
  value = $($el).val();
  match = value.match(/src\s*=\s*["|'](.*)["|'][\s|>|\/>]/i)
  if(value == '' || (match != null && /^https/i.test(match[1]) && !/\u2018|\u2019|\u201c|\u201d/.test(value))) {
    return true;
  } else {
    return false;
  }
}


Orbit.defaults.timerDelay = 2000;
